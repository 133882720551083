import { validate } from 'multihashes/src/index'
import numbro from 'numbro'

export const numFormatter = (value: any) => {
  if (!value) {
    return 0
  }

  const absoluteValue = Math.abs(value)
  if (absoluteValue < 1000) {
    return value
  }

  const exponentialValue = absoluteValue.toExponential().toString()
  const valueComponents = exponentialValue.split('e+')
  const exponent = parseInt(valueComponents[1])
  let shortValue = absoluteValue
  let suffix = ''
  // if (exponent >= 3 && exponent < 6) {
  //   shortValue = absoluteValue / Math.pow(10, 3);
  //   suffix = 'K';
  // }
  if (exponent >= 6 && exponent < 9) {
    shortValue = absoluteValue / Math.pow(10, 6)
    suffix = 'M'
  }
  if (exponent >= 9 && exponent < 12) {
    shortValue = absoluteValue / Math.pow(10, 9)
    suffix = 'B'
  }
  if (exponent >= 12 && exponent < 15) {
    shortValue = absoluteValue / Math.pow(10, 12)
    suffix = 'T'
  }
  if (exponent >= 15 && exponent < 18) {
    shortValue = absoluteValue / Math.pow(10, 15)
    suffix = 'QUAD'
  }
  if (exponent >= 18 && exponent < 21) {
    shortValue = absoluteValue / Math.pow(10, 18)
    suffix = 'QUIN'
  }
  if (exponent >= 21 && exponent < 24) {
    shortValue = absoluteValue / Math.pow(10, 21)
    suffix = 'SEX'
  }
  if (exponent >= 24 && exponent < 27) {
    shortValue = absoluteValue / Math.pow(10, 24)
    suffix = 'SEPT'
  }
  if (exponent >= 27) {
    shortValue = absoluteValue / Math.pow(10, 27)
    suffix = 'OCT'
  }

  return (
    shortValue.toLocaleString('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }) +
    ' ' +
    suffix
  )
}

// using a currency library here in case we want to add more in future
export const formatDollarAmount = (num: number | undefined, digits = 2, round = true) => {
  if (num === 0) return '$0.00'
  if (!num) return '-'
  if (num < 0.001 && digits <= 3) {
    return '<$0.001'
  }

  return numbro(num).formatCurrency({
    average: round,
    mantissa: num > 1000 ? 2 : digits,
    abbreviations: {
      million: 'M',
      billion: 'B',
    },
  })
  // return `$${numFormatter(num)}`
}

// using a currency library here in case we want to add more in future
export const formatAmount = (num: number | undefined, digits = 3) => {
  if (num === 0) return '0'
  if (!num) return '-'
  if (num < 0.001) {
    return '<0.001'
  }

  return numbro(num).format({
    average: true,
    mantissa: num > 1000 ? 2 : digits,
    abbreviations: {
      million: 'M',
      billion: 'B',
    },
  })
  // return millify(num)
  // return numFormatter(num)
}

export const toFixedNumber = (fixedDecimals: number, number: number | undefined) => {
  if (!number) {
    return 0
  }
  const numberString = fixedDecimals ? parseFloat(number.toFixed(fixedDecimals)) : number
  return numberString
}
