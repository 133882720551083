// used to mark unsupported tokens, these are hosted lists of unsupported tokens

import { SupportedChainId } from './chains'

export const UNSUPPORTED_LIST_URLS: string[] = []
// export const OPTIMISM_LIST = ''
// export const ARBITRUM_LIST = 'https://bridge.arbitrum.io/token-list-42161.json'
// export const POLYGON_LIST =
//   'https://unpkg.com/quickswap-default-token-list@1.2.2/build/quickswap-default.tokenlist.json'
// export const CELO_LIST = 'https://celo-org.github.io/celo-token-list/celo.tokenlist.json'
// export const BNB_LIST = 'https://raw.githubusercontent.com/plasmadlt/plasma-finance-token-list/master/bnb.json'

// export const LIGHTLINK_PEGASUS_LIST = 'https://ipfs.io/ipfs/QmPe2bcHw93cSFg5ogdGBakK6yBBgREd6hmr594dphz2Pe/'
// export const LIGHTLINK_PHOENIX_LIST = 'https://ipfs.io/ipfs/QmdtsuxFmKNV6sKqgvLNGpjViz5FwpZjV7zTfAQhFa1upk/'
export const LIGHTLINK_PEGASUS_LIST = 'https://ipfs.io/ipfs/QmcS1oawSZWD1t2TaGpjwqfLNGs3NBanxYTtUXKvKhyDVx/'
export const LIGHTLINK_PHOENIX_LIST = 'https://ipfs.io/ipfs/QmfZjp3DtBoYeST7UnUEaBspW9gnSDsXKpZzqVtbQPXLTE/'

// lower index == higher priority for token import
export const DEFAULT_LIST_OF_LISTS: string[] = [
  LIGHTLINK_PEGASUS_LIST,
  LIGHTLINK_PHOENIX_LIST,
  ...UNSUPPORTED_LIST_URLS, // need to load unsupported tokens as well
]

// default lists to be 'active' aka searched across
export const DEFAULT_ACTIVE_LIST_URLS: string[] = [LIGHTLINK_PEGASUS_LIST, LIGHTLINK_PHOENIX_LIST]

export const ChainUrlMapping: any = {
  [SupportedChainId.LIGHTLINK_PHOENIX]: 'https://phoenix.lightlink.io/api/v2/tokens/',
  [SupportedChainId.LIGHTLINK_PEGASUS]: 'https://pegasus.lightlink.io/api/v2/tokens/',
}
