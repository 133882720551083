import { getAddress } from '@ethersproject/address'
import { BigNumber } from '@ethersproject/bignumber'
import { AddressZero } from '@ethersproject/constants'
import { Contract } from '@ethersproject/contracts'
import { JsonRpcSigner, Web3Provider } from '@ethersproject/providers'
import { Currency, CurrencyAmount, Fraction, Percent, Token } from '@uniswap/sdk-core'
import { SupportedChainId } from 'constants/chains'
import {
  ArbitrumNetworkInfo,
  AvalancheNetworkInfo,
  BaseNetworkInfo,
  BNBNetworkInfo,
  CeloNetworkInfo,
  LightlinkPegasusNetworkInfo,
  LightlinkPhoenixNetworkInfo,
  NetworkInfo,
  PolygonNetworkInfo,
} from 'constants/networks'
import JSBI from 'jsbi'
import { TokenAddressMap } from '../state/lists/hooks'
import { OptimismNetworkInfo } from '../constants/networks'
import { TokenData } from 'state/tokens/reducer'
import { PoolData } from 'state/pools/reducer'
import { Transaction } from 'types'

// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value: any): string | false {
  try {
    return getAddress(value)
  } catch {
    return false
  }
}

const ETHERSCAN_PREFIXES: { [chainId: number]: string } = {
  [SupportedChainId.MAINNET]: '',
  [SupportedChainId.ROPSTEN]: 'ropsten.',
  [SupportedChainId.RINKEBY]: 'rinkeby.',
  [SupportedChainId.GOERLI]: 'goerli.',
  [SupportedChainId.KOVAN]: 'kovan.',
  [SupportedChainId.OPTIMISM]: 'optimistic.',
  [SupportedChainId.OPTIMISTIC_KOVAN]: 'kovan-optimistic.',
}

export function getEtherscanLink(
  chainId: number,
  data: string,
  type: 'transaction' | 'token' | 'address' | 'block',
  networkVersion: NetworkInfo
): string {
  const prefix =
    networkVersion === LightlinkPegasusNetworkInfo
      ? 'https://pegasus.lightlink.io/'
      : networkVersion === LightlinkPhoenixNetworkInfo
      ? 'https://phoenix.lightlink.io/'
      : networkVersion === BNBNetworkInfo
      ? 'https://bscscan.com/'
      : networkVersion === PolygonNetworkInfo
      ? 'https://polygonscan.com/'
      : networkVersion === CeloNetworkInfo
      ? 'https://celoscan.io/'
      : networkVersion === AvalancheNetworkInfo
      ? 'https://avascan.info/'
      : networkVersion === ArbitrumNetworkInfo
      ? 'https://arbiscan.io/'
      : networkVersion === BaseNetworkInfo
      ? 'https://basescan.org/'
      : networkVersion === OptimismNetworkInfo
      ? 'https://optimistic.etherscan.io'
      : `https://${ETHERSCAN_PREFIXES[chainId] || ETHERSCAN_PREFIXES[1]}etherscan.io`

  if (networkVersion === OptimismNetworkInfo) {
    switch (type) {
      case 'transaction': {
        return `${prefix}/tx/${data}`
      }
      case 'token': {
        return `${prefix}/address/${data}`
      }
      case 'block': {
        return `https://optimistic.etherscan.io`
      }
      case 'address':
      default: {
        return `${prefix}/address/${data}`
      }
    }
  }

  if (networkVersion === ArbitrumNetworkInfo) {
    switch (type) {
      case 'transaction': {
        return `${prefix}/tx/${data}`
      }
      case 'token': {
        return `${prefix}/address/${data}`
      }
      case 'block': {
        return 'https://arbiscan.io/'
      }
      case 'address':
      default: {
        return `${prefix}/address/${data}`
      }
    }
  }

  switch (type) {
    case 'transaction': {
      return `${prefix}/tx/${data}`
    }
    case 'token': {
      return `${prefix}/token/${data}`
    }
    case 'block': {
      return `${prefix}/block/${data}`
    }
    case 'address':
    default: {
      return `${prefix}/address/${data}`
    }
  }
}

export const currentTimestamp = () => 1690599600000 //new Date().getTime()

// shorten the checksummed version of the input address to have 0x + 4 characters at start and end
export function shortenAddress(address: string, chars = 4): string {
  const parsed = isAddress(address)
  if (!parsed) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }
  return `${parsed.substring(0, chars + 2)}...${parsed.substring(42 - chars)}`
}

// add 10%
export function calculateGasMargin(value: BigNumber): BigNumber {
  return value.mul(BigNumber.from(10000).add(BigNumber.from(1000))).div(BigNumber.from(10000))
}

// converts a basis points value to a sdk percent
export function basisPointsToPercent(num: number): Percent {
  return new Percent(JSBI.BigInt(num), JSBI.BigInt(10000))
}

const ONE = new Fraction(1, 1)
export function calculateSlippageAmount(value: CurrencyAmount<Currency>, slippage: Percent): [JSBI, JSBI] {
  if (slippage.lessThan(0) || slippage.greaterThan(ONE)) throw new Error('Unexpected slippage')
  return [value.multiply(ONE.subtract(slippage)).quotient, value.multiply(ONE.add(slippage)).quotient]
}
// account is not optional
export function getSigner(library: Web3Provider, account: string): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked()
}

// account is optional
export function getProviderOrSigner(library: Web3Provider, account?: string): Web3Provider | JsonRpcSigner {
  return account ? getSigner(library, account) : library
}

// account is optional
export function getContract(address: string, ABI: any, library: Web3Provider, account?: string): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }

  return new Contract(address, ABI, getProviderOrSigner(library, account) as any)
}

export function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

export function isTokenOnList(tokenAddressMap: TokenAddressMap, token?: Token): boolean {
  return Boolean(token?.isToken && tokenAddressMap[token.chainId]?.[token.address])
}

export function feeTierPercent(fee: number): string {
  return (fee / 10000).toPrecision(1) + '%'
}

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined
}

const WHITE_LISTED_TOKENS: string[] = [
  '0x04E4929B89fc1483B6C6839c4455AbE1C1Caa811',
  '0x7EbeF2A4b1B09381Ec5B9dF8C5c6f2dBECA59c73',
  '0x81A1f39f7394c4849E4261Aa02AaC73865d13774',
  '0xe06FE7298243D8e47092a1E0679351f305e5f856',
  '0xe03bA6851335D01d38Dd6087c46C26008D16aF92',
  '0x18fB38404DADeE1727Be4b805c5b242B5413Fa40',
  '0x49F65C3FfC6e45104ff5cB00e6030C626157a90b',
  '0x6308fa9545126237158778e74AE1b6b89022C5c0',
  '0x46A5e3Fa4a02B9Ae43D9dF9408C86eD643144A67',
  '0xF42991f02C07AB66cFEa282E7E482382aEB85461', // testnet
  '0x60d7966bdf03f0Ec0Ac6de7269CE0E57aAd6e9c2', // testnet
  '0xe03fC4F4F9c93301923124550e685F74e3292837', // testnet
  '0xC2b0Cb7bE78b29d90F198D7bD14c3B06d5f5208e', // testnet
  '0x3cf2c147d43C98Fa96d267572e3FD44A4D3940d4', // testnet
  '0x4B6b9B31c72836806B0B1104Cf1CdAB8A0E3BD66', // testnet
  '0x057e8e2bC40ECff87e6F9b28750D5E7AC004Eab9', // testnet
  '0x9Ee1Aa18F3FEB435f811d6AE2F71B7D2a4Adce0B',
  '0xd9d7123552fA2bEdB2348bB562576D67f6E8e96E',
]

export function whiteListed(value: TokenData): value is TokenData {
  return WHITE_LISTED_TOKENS.some((token) => token.toLowerCase() === value.address.toLowerCase())
}

export function whiteListedMovers(value: {
  data: TokenData | undefined
  lastUpdated: number | undefined
}): value is {
  data: TokenData | undefined
  lastUpdated: number | undefined
} {
  return WHITE_LISTED_TOKENS.some((token) => token.toLowerCase() === value.data?.address.toLowerCase())
}

export function whiteListedPools(value: PoolData): value is PoolData {
  return (
    WHITE_LISTED_TOKENS.some((token) => token.toLowerCase() === value.token0.address.toLowerCase()) &&
    WHITE_LISTED_TOKENS.some((token) => token.toLowerCase() === value.token1.address.toLowerCase())
  )
}

export function whiteListedTransactions(value: Transaction): value is Transaction {
  return (
    WHITE_LISTED_TOKENS.some((token) => token.toLowerCase() === value.token0Address.toLowerCase()) &&
    WHITE_LISTED_TOKENS.some((token) => token.toLowerCase() === value.token1Address.toLowerCase())
  )
}
