export function shortenValue(value: string, charsStart = 4, charsEnd?: number): string {
  if (!value) return ''

  if (value.length > 20) {
    return `${value.substring(0, charsStart)}...${value.substring(value.length - (charsEnd || charsStart))}`
  } else {
    return value
  }
}
export function truncateFormatPriceImpact(value: any) {
  if (value && value.length <= 10) {
    return value
  }
  console.log('LLKL', typeof value)
  const truncatedValue = value && value.slice(0, 10) + '...'

  return truncatedValue
}
