import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import gql from 'graphql-tag'
import { Transaction, TransactionType } from 'types'
import { formatTokenSymbol } from 'utils/tokens'

const GLOBAL_SWAPS = gql`
  query {
    swaps(first: 100, orderBy: timestamp, orderDirection: desc, subgraphError: allow) {
      id
      timestamp
      pool {
        token0 {
          id
          symbol
        }
        token1 {
          id
          symbol
        }
      }
      origin
      amount0
      amount1
      amountUSD
    }
  }
`

const GLOBAL_MINT = gql`
  query {
    mints(first: 100, orderBy: timestamp, orderDirection: desc, subgraphError: allow) {
      id
      timestamp
      pool {
        token0 {
          id
          symbol
        }
        token1 {
          id
          symbol
        }
      }
      origin
      amount0
      amount1
      amountUSD
    }
  }
`

const GLOBAL_BURNS = gql`
  query {
    burns(first: 100, orderBy: timestamp, orderDirection: desc, subgraphError: allow) {
      id
      timestamp
      pool {
        token0 {
          id
          symbol
        }
        token1 {
          id
          symbol
        }
      }
      origin
      amount0
      amount1
      amountUSD
    }
  }
`

type TransactionBurns = {
  burns: {
    pool: {
      token0: {
        id: string
        symbol: string
      }
      token1: {
        id: string
        symbol: string
      }
    }
    owner: string
    origin: string
    amount0: string
    amount1: string
    amountUSD: string
  }[]
}

type TransactionMint = {
  mints: {
    pool: {
      token0: {
        id: string
        symbol: string
      }
      token1: {
        id: string
        symbol: string
      }
    }
    owner: string
    origin: string
    amount0: string
    amount1: string
    amountUSD: string
  }[]
}

type TransactionSwaps = {
  swaps: {
    pool: {
      token0: {
        id: string
        symbol: string
      }
      token1: {
        id: string
        symbol: string
      }
    }
    owner: string
    origin: string
    amount0: string
    amount1: string
    amountUSD: string
  }[]
}

export async function fetchTopTransactions(
  client: ApolloClient<NormalizedCacheObject>
): Promise<Transaction[] | undefined> {
  try {
    const { data: swapData, error: swapError, loading: swapLoading } = await client.query<TransactionSwaps>({
      query: GLOBAL_SWAPS,
      fetchPolicy: 'network-only',
    })
    const { data: mintData, error: mintError, loading: mintLoading } = await client.query<TransactionMint>({
      query: GLOBAL_MINT,
      fetchPolicy: 'network-only',
    })
    const { data: burnData, error: burnError, loading: burnLoading } = await client.query<TransactionBurns>({
      query: GLOBAL_BURNS,
      fetchPolicy: 'network-only',
    })

    if (
      swapError ||
      mintError ||
      burnError ||
      swapLoading ||
      mintLoading ||
      burnLoading ||
      !swapData ||
      !mintData ||
      !burnData
    ) {
      return undefined
    }
    const mintDataFormat = mintData.mints.map((m: any) => {
      return {
        type: TransactionType.MINT,
        hash: m.id,
        timestamp: m.timestamp,
        sender: m.origin,
        token0Symbol: formatTokenSymbol(m.pool.token0.id, m.pool.token0.symbol),
        token1Symbol: formatTokenSymbol(m.pool.token1.id, m.pool.token1.symbol),
        token0Address: m.pool.token0.id,
        token1Address: m.pool.token1.id,
        amountUSD: parseFloat(m.amountUSD),
        amountToken0: parseFloat(m.amount0),
        amountToken1: parseFloat(m.amount1),
      }
    })
    const swapDataFormat = swapData.swaps.map((m: any) => {
      return {
        type: TransactionType.SWAP,
        hash: m.id,
        timestamp: m.timestamp,
        sender: m.origin,
        token0Symbol: formatTokenSymbol(m.pool.token0.id, m.pool.token0.symbol),
        token1Symbol: formatTokenSymbol(m.pool.token1.id, m.pool.token1.symbol),
        token0Address: m.pool.token0.id,
        token1Address: m.pool.token1.id,
        amountUSD: parseFloat(m.amountUSD),
        amountToken0: parseFloat(m.amount0),
        amountToken1: parseFloat(m.amount1),
      }
    })

    const burnsDataFormat = burnData.burns.map((m: any) => {
      return {
        type: TransactionType.BURN,
        hash: m.id,
        timestamp: m.timestamp,
        sender: m.origin,
        token0Symbol: formatTokenSymbol(m.pool.token0.id, m.pool.token0.symbol),
        token1Symbol: formatTokenSymbol(m.pool.token1.id, m.pool.token1.symbol),
        token0Address: m.pool.token0.id,
        token1Address: m.pool.token1.id,
        amountUSD: parseFloat(m.amountUSD),
        amountToken0: parseFloat(m.amount0),
        amountToken1: parseFloat(m.amount1),
      }
    })

    const formatted = [...mintDataFormat, ...swapDataFormat, ...burnsDataFormat]

    return formatted
  } catch {
    return undefined
  }
}
